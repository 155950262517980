import { HNCoreFrontendWebAppInvitation, HNCoreFrontendWebForside } from '../types/Resources';

import LanguageLocales from '@helsenorge/core-utils/constants/languages';
import { MarkdownType } from '@helsenorge/framework-utils/components/safe-html';
import { getModifiedResourcesForTest as getModifiedResources } from '@helsenorge/framework-utils/resources';

import appResources from './HN.CoreFrontend.Web.AppInvitation.nb-NO.json';
import defaultResources from './HN.CoreFrontend.Web.Forside.nb-NO.json';
import Projects from '../constants/projects';

type ResourceItem = { [key: string]: string } | Record<string, string | MarkdownType>;

export function getResources(projectName: string, language: LanguageLocales = LanguageLocales.NORWEGIAN): Promise<ResourceItem> {
  if (projectName === Projects.Forside && language === LanguageLocales.NORWEGIAN) {
    return Promise.resolve(defaultResources);
  }

  return import(`./${projectName}.${language}.json`);
}

export const getResourceItem = (projectName: string, language: LanguageLocales = LanguageLocales.NORWEGIAN): ResourceItem | undefined => {
  if (projectName === Projects.AppInvitation && language === LanguageLocales.NORWEGIAN) {
    return getModifiedResources(appResources) as HNCoreFrontendWebAppInvitation;
  }

  return getModifiedResources(import(`./${projectName}.${language}.json`));
};

export function getResourcesTestHelper(): HNCoreFrontendWebForside {
  return getModifiedResources(defaultResources) as HNCoreFrontendWebForside;
}
